import React from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
} from "@mui/material";

// theme
import theme from "../../theme";

// clients
import QatarAirways from "../../assets/qatar-airways.png";
import AirCanada from "../../assets/air-canada.png";
import OmanAir from "../../assets/oman-air.png";
import TurkishAirlines from "../../assets/turkish-airline.jpg";
import AirMauritius from "../../assets/air-mauritius.png";
import GulfAir from "../../assets/gulf-air.png";

// clients
const clients = [
  {
    id: "1",
    image: QatarAirways,
    name: "Qatar Airways",
    website: "www.qatarairways.com",
  },
  {
    id: "2",
    image: AirCanada,
    name: "Air Canada",
    website: "www.aircanada.com",
  },
  {
    id: "3",
    image: OmanAir,
    name: "Oman Air",
    website: "www.omanair.com",
  },
  {
    id: "4",
    image: TurkishAirlines,
    name: "Turkish Airlines",
    website: "www.turkishairlines.com",
  },
  {
    id: "5",
    image: AirMauritius,
    name: "Air Mauritius",
    website: "www.airmauritius.com",
  },
  {
    id: "6",
    image: GulfAir,
    name: "Gulf Air",
    website: "www.gulfair.com",
  },
];

const About = ({ id }) => {
  return (
    <Container maxWidth="lg" sx={{ my: 11 }} id={id}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          align="center"
          sx={{
            px: 5,
            py: 1,
            mb: 3,
            color: theme.palette.primary.main,
            fontWeight: 600,
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: "18px",
          }}
        >
          Our Portfolio
        </Typography>
        <Typography
          align="center"
          variant="h3"
          sx={{ fontWeight: 900, mb: 4 }}
          gutterBottom
        >
          Our Featured Clients
        </Typography>
        <Typography align="center" variant="body1" sx={{ mb: 6 }} gutterBottom>
          We have a responsive and dedicated office team who are available 24
          hours to answer any queries and provide administrative support to our
          clients.
        </Typography>
      </Box>
      <Grid container direction="row" justifyContent="center" spacing={4}>
        {clients.map((client) => (
          <Grid item md={4} sm={6} xs={12} key={client.id}>
            <Card sx={{ maxWidth: 345 }} elevation={3}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="250"
                  image={client.image}
                  alt={client.name}
                  sx={{
                    objectFit: "contain",
                  }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {client.name}
                  </Typography>
                  <Typography variant="body2" color="primary.main">
                    {client.website}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default About;
