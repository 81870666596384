import React from "react";
import { Link } from "react-scroll";
import {
  Container,
  Box,
  Grid,
  Typography,
  Stack,
  Button,
  useMediaQuery,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

// material icons
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

// components
import Video from "./Video";
import { MeetGreetWidget } from "../MeetGreet";
import HeroBackgroundImage from "../../assets/hero-bg.jpg";

const Hero = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexGrow: 1,
        backgroundColor: grey[200],
        py: { xs: 2, sm: 12 },
        backgroundImage: `url(${HeroBackgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPistion: "right center",
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MeetGreetWidget />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: 2,
                borderRadius: "4px",
                background: "rgba(204, 204, 204, .35)",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 900,
                  textAlign: { xs: "center", md: "left" },
                }}
                gutterBottom
              >
                Our Dedication, Your Peace of Mind
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  mb: { xs: 2 },
                }}
              >
                Arriving at the airport? Start your journey stress-free with
                Travel Serve’s premium services. Whether you choose our Meet &
                Greet service, Chauffeur service, or VIP assistance, our team
                will be there to assist you from the moment you land, ensuring a
                seamless and hassle-free experience.
              </Typography>
              <Stack
                direction="row"
                justifyContent={isSmallScreen ? "center" : "start"}
                alignItems={isSmallScreen ? "center" : "start"}
                spacing={2.5}
                sx={{ mt: 3 }}
              >
                <Link
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <Button variant="contained" color="primary">
                    Get In Touch
                  </Button>
                </Link>
                <Video
                  startIcon={<PlayCircleFilledWhiteIcon />}
                  btnText="Watch Intro"
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;
