import React from "react";
import { Container, Box, Grid, Typography, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

// config
import { config } from "../../config";

// material ui styled
import { styled } from "@mui/material/styles";

// theme
import theme from "../../theme";

// assets
import AboutImgSrc from "../../assets/about.jpg";

// Logo
const AboutImg = styled("img")(({ theme }) => ({
  minWidth: "100%",
  height: "auto",
  padding: 0,
  margin: 0,
  borderRadius: "6px",
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&.Mui-selected": {
      color: "primary",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

// data
const data = [
  {
    id: "1",
    title: "Who We Are?",
    description:
      "Travelserve Limited was founded in 2007 by Mr. Anwar Kureembokus with a vision to provide first-class airport concierge services, chauffeuring, cancelled flight management, and hotel bookings. From humble beginnings, we have grown into a trusted partner for prestigious airline clients and major hotel chains, delivering tailored solutions to meet their unique needs. Our highly professional team ensures the highest standards of safety, with all staff holding the necessary licenses for their respective services. As a rapidly expanding company, we now proudly operate across all major London airports.",
  },
  {
    id: "2",
    title: "Why Choose Us?",
    description:
      "Over the past 15 years, Travel Serve has transformed from a small team into a highly professional and structured business. We are proud to have had the opportunity to collaborate with some of the world’s leading airlines and hotel chains. Today, we partner with over 29 airlines, including Oman Air, Qatar Airways, Gulf Air, Air Canada, Air China, Air India, Turkish Airlines, and Air Mauritius and more. Our vision is to become the preferred bespoke travel service provider at every destination where our customers and airline partners require our expertise.",
  },
  {
    id: "3",
    title: "What We Offer?",
    description:
      "At Travel Serve Limited, we provide a comprehensive range of premium travel support services to ensure a seamless and stress-free experience for passengers and airline partners. Our airport concierge service includes personalized meet-and-greet assistance, fast-track immigration, luggage handling, and VIP lounge access. We offer luxury chauffeuring services for airport transfers, business travel, and executive transportation. Our canceled flight management service provides rapid assistance, and coordination with airlines and hotels. Additionally, we facilitate hotel bookings with exclusive partnerships and negotiated rates for corporate and airline clients. With tailored travel solutions, dedicated account management, and 24/7 support, we are committed to delivering excellence at all major London airports.",
  },
];

const About = ({ id }) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ my: 11 }} id={id}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          align="center"
          sx={{
            px: 5,
            py: 1,
            mb: 3,
            color: theme.palette.primary.main,
            fontWeight: 600,
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: "18px",
          }}
        >
          About Us
        </Typography>
        <Typography
          align="center"
          variant="h3"
          sx={{ fontWeight: 900, mb: 4 }}
          gutterBottom
        >
          A Digital Airport Concierge Company
        </Typography>
        <Typography align="center" variant="body1" sx={{ mb: 6 }} gutterBottom>
          Travel Serve delivers world-class concierge and travel services to our
          global clients.
        </Typography>
      </Box>
      <Grid container direction="row" justifyContent="center" spacing={4}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AboutImg src={AboutImgSrc} alt={config.client.name} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TabContext value={value}>
            <Box
              sx={{
                borderRadius: "0px",
                px: 2,
                py: 1,
              }}
            >
              <TabList onChange={handleChange}>
                {data.map((service) => (
                  <StyledTab
                    key={service.id}
                    label={service.title}
                    value={service.id}
                  />
                ))}
              </TabList>
            </Box>
            {data.map((service) => (
              <TabPanel key={service.id} value={service.id}>
                <Typography>{service.description}</Typography>
              </TabPanel>
            ))}
          </TabContext>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
